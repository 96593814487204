<template>
  <div class="bindSuccess">
    <div class="navBar">
      <div @click="$router.go(-2)" class="back">
        <svg-icon class="leftBtn" iconClass="back"></svg-icon>
        <span>返回</span>
      </div>
      <div class="title">手机号</div>
      <div class="rightBtn"></div>
    </div>
    <svg-icon class="successIcon" iconClass="bindSuccess"></svg-icon>
    <div class="phone">{{ $route.query.phone }}</div>
    <div class="tip">换绑成功，点击左上角返回</div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.bindSuccess {
  .navBar {
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    background-color: #fff;
    box-sizing: border-box;
    border-bottom: 1px solid rgb(217, 217, 217);
    .back {
      width: 80px;
      display: flex;
      align-items: center;

      .leftBtn {
        width: 24px;
        height: 24px;
        font-size: 24px;
      }

      span {
        font-size: 18px;
      }
    }

    .title {
      flex: 1;
      font-size: 18px;
      text-align: center;
      color: #000;
    }

    .rightBtn {
      width: 80px;
    }
  }
  .successIcon {
    width: 63px;
    height: 63px;
    display: block;
    margin: 70px auto 0px;
  }
  .phone {
    font-size: 18px;
    margin: 14px 0;
    text-align: center;
  }
  .tip {
    font-size: 16px;
    color: rgb(153, 153, 153);
    text-align: center;
  }
}
</style>
